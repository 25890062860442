import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/skillscards" })
                ]),
                _: 1
              }),
              ($options.currentCard)
                ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode("Skills Card " + _toDisplayString($options.currentCard.genre) + " N° " + _toDisplayString($options.currentCard.skills_card), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          ($options.currentCard)
            ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                default: _withCtx(() => [
                  (!$options.currentCard || !$options.currentCard.exams.length)
                    ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Nessuna certificazione trovata ")
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(_component_ion_card_header, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Certificati ottenuti")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.currentCard.exams, (exam, examIndex) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            lines: "none",
                            key: 'exam_'+examIndex
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, { style: {"width":"100%"} }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_subtitle, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(exam.execution_date), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(exam.description), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("p", null, "test_center: " + _toDisplayString(exam.execution_center_code), 1),
                                      _createElementVNode("p", null, "Punteggio ottenuto: " + _toDisplayString(exam.score), 1),
                                      (exam.promotion === 'Y')
                                        ? (_openBlock(), _createBlock(_component_ion_badge, {
                                            key: 0,
                                            color: "success"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("Promosso")
                                            ]),
                                            _: 1
                                          }))
                                        : (_openBlock(), _createBlock(_component_ion_badge, {
                                            key: 1,
                                            color: "danger"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("Non promosso")
                                            ]),
                                            _: 1
                                          }))
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ], 64))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}