
  import { IonModal, IonDatetime, IonDatetimeButton, IonIcon, IonProgressBar, IonItemDivider, IonGrid, IonCol, IonRow, IonRadioGroup, IonRadio, IonCheckbox,  IonHeader,IonFooter, IonToolbar,IonTitle, IonButton,IonButtons,IonBackButton, IonContent,IonSelect,IonSelectOption, IonPage, IonItem, IonInput,  IonLabel, IonText, IonList, toastController  } from '@ionic/vue';
  import axios from 'axios';
  import { pencil, chevronForwardOutline, checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
  import {mapGetters, mapState} from "vuex";
  import {Form, Field} from 'vee-validate';
  import _ from 'lodash';
  import moment from 'moment';

  export default {
    components: {IonModal, IonDatetime, IonDatetimeButton, IonIcon, IonProgressBar, IonItemDivider, IonGrid, IonCol, IonRow, IonRadioGroup, IonRadio, IonCheckbox, IonHeader,IonFooter, IonToolbar, IonTitle,IonButton,IonButtons,IonBackButton, IonContent,IonSelect,IonSelectOption, IonPage, IonItem, IonInput, IonLabel, IonText, IonList, VForm: Form, Field},
    computed: {
      ...mapState({
        //user: "user"
        parameters: 'parameters'
      }),
      calculatedQuote(){
        let amount = 80;
        if(this.user.tiposocio.toLowerCase() === 'ordinario' && (this.user.docente_ata || this.user.supervisoreecdl || this.user.digital_animator)){
          amount = 20;
        }else if(this.user.clubFIDA || this.user.IEEE || this.user.aium || this.user.ACM){
          amount = 64;
        }
        return amount;
      },
      getYearRef(){
        if(this.parameters && this.parameters.REGISTER_ACTIVE_YEAR){
          return this.parameters.REGISTER_ACTIVE_YEAR;
        }
        return '';
      },
    },
    setup(){
      return {pencil, chevronForwardOutline, checkmarkCircleOutline, closeCircleOutline};
    },
    beforeMount(){
      this.user = _.cloneDeep(this.defaultUser);
    },
    mounted(){
      const paypalScript = document.createElement('script');
      if(process.env.NODE_ENV === 'development'){
        paypalScript.setAttribute('src',"https://www.paypal.com/sdk/js?client-id=AXDAFDEJUY8PLj_T4zK48D7_0tAZF4CrQdbEdIUX98aN-zK89IO03oHEZx-YfA5T1DtpIALd4JNx0PxF&components=buttons&currency=EUR");
      }else{
        paypalScript.setAttribute('src',"https://www.paypal.com/sdk/js?client-id=Ab15W2hMyi03hkTN0f43xD1E_BEX7QqPXeWHD0LS29o88ZBPcWzpiOZwlD8Sp0hQ5iF87tMJv4szS6DU&components=buttons&currency=EUR");
      }
      document.head.appendChild(paypalScript);
      this.$store.commit('CLEAR_USER_AUTH');
      if(process.env.NODE_ENV === 'development'){
        this.user = _.cloneDeep(this.testUserData);
      }
    },
    data(){
      return {
        step: 1,
        totalSteps: 6,
        progress: 0.25,
        associateId: null,
        paypalButtonsCreated: false,
        paymentFeedback: {
          status: null
        },
        aicaCode: null,
        tipiSoci: [
          {code: "ORDINARIO", name: "Ordinario"},
          {code: "PROFESSIONISTA", name: "Professionista"},
          {code: "STUDENTE", name: "Studente"},
          // {code: "collettivo", name: "Studente"},
        ],
        sezioni: [
          {code: "CAL", name: "Calabria"},
          {code: "CAM", name: "Campania"},
          {code: "EMR", name: "Emilia Romagna - Marche"},
          {code: "INT", name: "Internazionale"},
          {code: "LAZ", name: "Lazio"},
          {code: "LIG", name: "Liguria"},
          {code: "LOM", name: "Lombardia"},
          {code: "FVG", name: "Nord-Est"},
          {code: "PIE", name: "Piemonte"},
          {code: "PUG", name: "Puglia"},
          {code: "SIC", name: "Sicilia"},
          {code: "TOS", name: "Toscana"},
          {code: "VDA", name: "Valdadige"},
        ],
        testUserData: {
          "Preinsert": "True",
          "tiposocio": "ORDINARIO",
          "passwd": "password",
          "cfisc": "RSSMRA71L01I082Y",
          "cognome": "Rossetti",
          "nome": "Mario",
          "datanasc": "1971-07-01",
          "pvnasc": "VR",
          "comnasc": "Verona",
          "naznasc": "IT",
          "recindir": "Via dell'industria, 1",
          "recemail": "helpdesk@digital74.it",
          "reccap": "37127",
          "recpv": "VR",
          "reccom": "Verona",
          "reccext": "",
          "recnaz": "IT",
          "recph": "0456789010",
          "reccell": "3333333333",
          "privacy": "Ho preso visione",
          "privacy1ab": "",
          "privacy1c": "Non consento",
          "privacy1d": "Consento",
          "privacy1e": "Non consento",
          "privacy1f": "Consento",
          "privacy1g": "Non consento",
          "privacy2": "Non consento",
          payment_mode: null,
          send_to: null,
          "azragsoc": "Azienda S.R.L.",
          "azcfisc": "37127",
          "azpiva": "0412345678",
          "azindir": "Prova via, 10",
          "azcap": "37100",
          "azpv": "VR",
          "azcom": "Verona",
          "aznaz": "IT",
          "azph": "04512345679",
          "azemail": "azienda@email.com",
        },
        user: {},
        defaultUser: {
          Preinsert: "True",
          passwd: "",
          cdaica: "",
          dataiscr: "",
          cfisc: "",
          cognome: "",
          nome: "",
          tiposocio: "",
          datanasc: moment().format('YYYY-MM-DD'),
          pvnasc: "",
          comnasc: "",
          naznasc: "",
          recindir: "",
          recemail: "",
          reccap: "",
          recpv: "",
          reccom: "",
          reccext: "",
          recnaz: "",
          recph: "",
          reccell: "",
          privacy: "",
          privacy1ab: "",
          privacy1c: "",
          privacy1d: "",
          privacy1e: "",
          privacy1f: "",
          privacy1g: "",
          privacy2: "",
          payment_mode: null,
          send_to: null,
          azragsoc: "",
          azcfisc: "",
          azpiva: "",
          azindir: "",
          azcap: "",
          azpv: "",
          azcom: "",
          aznaz: "",
          azph: "",
          azemail: ""
        },
        // step: 1,
        ccode: null,
      };
    },
    watch:{
      'user.tiposocio': function(newValue, oldValue){
        if(newValue.toLowerCase() !== 'ordinario'){
          this.user.supervisoreecdl = false;
          this.user.docente_ata = false;
          this.user.digital_animator = false;
        }
      }
    },
    methods: {
      onTiposocioChange(){
        console.log('onTiposocioChange');
      },
      moment,
      createPaypalButton(){
        const taxCode = this.user.cfisc;
        const aicaCode = this.aicaCode;
        const year = this.user.annorif;
        const amount = this.user.quote;
        const tipoSocio = this.user.tiposocio;
        const paymentFeedback = this.paymentFeedback;
        // const vm = this;
        this.paypalButtonsCreated = true;
        window.paypal.Buttons({
          style: {
            label: 'pay',
            layout: 'horizontal',
            tagline: 'false'
          },
          createOrder() {
            // axios.post('payment-request', {
            //   tax_code: this.user.cfisc,
            //   aica_code: this.aicaCode,
            //   year: this.getYearRef,
            //   amount: this.getQuota,
            // })
            return fetch(axios.defaults.baseURL+"payment-request", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                tax_code: taxCode,
                aica_code: aicaCode,
                tipo_socio: tipoSocio,
                year: year,
                amount: amount,
              })
            })
              .then((response) => response.json())
              .then((order) => order.id);
          },
          onApprove(data) {
            console.log("pagamento effettuato con successo");
            // This function captures the funds from the transaction.
            return fetch(axios.defaults.baseURL+"payment-submit", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                orderID: data.orderID
              })
            })
              .then((response) => response.json())
              .then((details) => {
                // This function shows a transaction success message to your buyer.
                console.log('onApproveResponse: ', JSON.stringify(details));
                paymentFeedback.status = details.status;

                // alert('Transaction completed by ' + details.payer.name.given_name);
              });
          },
          onCancel(data){
            console.log('onCancelData: ', JSON.stringify(data));
          },
        }).render('#paypal-button-container');
      },
      goToDashboard(){
        this.$router.push({name: 'confirm.otp'});
        this.user = _.cloneDeep(this.defaultUser);
        this.step= 1;
        this.progress= 0.25;
        this.paymentFeedback.status= null;
      },
      submitQuotePayment(){
        axios.post('quote/', this.user, {
          auth: {
            username: this.user.cfisc,
            password: this.user.passwd,
          },
        }).then(response => {
          this.presentToast("Dati inviati con successo!", "success");
          this.$store.commit('SET_USER_AUTH', {username: this.user.cfisc, password: this.user.passwd});
          this.$router.push({name: 'confirm.otp'});
        });
      },
      goBack(){
        if(this.step > 1){
          this.step--;
          this.progress = this.step/this.totalSteps;
        }
      },
      goNext(){
        console.log("goNext");
        this.$refs['formStep'+this.step].validate().then(validation => {
          if(validation.valid === true){
            if(this.step === 1 && moment(this.user.datanasc).isAfter(moment().subtract(16, 'year'))){
              this.presentToast("Devi avere almeno 16 anni per poterti iscrivere! Verifica la data di nascita");
              return;
            }
            if(this.step < this.totalSteps){
              this.step++;
              this.progress = this.step/this.totalSteps;
            }else{
              this.saveData();
            }
          }else{
            this.presentToast("Non hai inserito tutti i dati obbligatori!", "danger");
          }
        });
        
        // if(this.step === 'anagraphic'){
        //   // this.saveToAica();
        //   this.step = 'section';
        //   this.progress = 0.50;
        // } else if(this.step === 'section'){
        //   this.step = 'payment';
        //   this.progress = 0.75;
        // } else if(this.step === 'payment'){
        //   //submit data
        //   this.progress = 1;
        // }
      },
      saveToAica(){
        //cambiamo format data di nascita
        this.user.datanasc = this.user.datanasc.split('-').reverse().join('/');
        this.user.cfisc = this.user.cfisc.toUpperCase();
        return axios.post('aica-proxy', this.user, {
          auth: {
            username: this.user.cfisc,
            password: this.user.passwd,
          },
        }).then(response => {
          if(response.data.ERROR && response.data.STATEMENT){
            this.presentToast(response.data.STATEMENT);
            return;
          }
          this.aicaCode = response.data.CODAICA;
          // this.presentToast("Dati inviati con successo!", "success");
          this.$store.commit('SET_USER_AUTH', {username: this.user.cfisc, password: this.user.passwd});
        });
      },
      simulatePaypalPayment(){
        const checkPaymentFeedback = this.checkPaymentFeedback;
        this.paymentFeedback.status = 'PENDING';
        return axios.post('payment-request', {
          aica_code : "100003",
          amount: 64,
          tax_code: "RSSMRA71L01I082Y",
          year: "2023"
        })
          .then(response => {
            console.log(response.data);
            const ref = window.open(response.data.approve_link, '_blank', 'cache=no,location=no');
            // // use this to return to some state after the external browser is closed
            const checkInterval = setInterval(function() {
              if(ref.closed) {
                clearInterval(checkInterval);
                checkPaymentFeedback(response.data.id);
              }
            }, 1000);
            ref.addEventListener('exit', function() {
              console.log("finestra pagamento chiusa correttamente");
              this.presentToast("Iscrizione completata con successo", "success");
            });
            ref.addEventListener('loadstop', function(event) {
              console.log('loadstop');
              console.log(event);
              // if (event.url.match("/payment-success") || event.url.match("/payment-denied")) {
              //   setTimeout(function () {
              //     ref.close();
              //   }, 5000);
              // }
            });
            //
            // // close the external browser when hitting confirmation or error (your backend needs to redirect to a route you can catch here)
            // ref.addEventListener('loadstop', function(event) {
            //   if (event.url.match("/api/confirmed") || event.url.match("/api/cancel") || event.url.match("/api/error")) {
            //     $timeout(function () {
            //       ref.close();
            //     }, 15000);
            //   }
            // });
          // this.presentToast("Dati inviati con successo!", "success");
          // this.$store.commit('SET_USER_AUTH', {username: this.user.cfisc, password: this.user.passwd});
          // this.$router.push({name: 'confirm.otp'});
        });
      },
      checkPaymentFeedback(orderId){
        return axios.get('payment-check/'+orderId)
          .then(response => {
            this.paymentFeedback.status = response.data.status;
            if(response.data.status === 'COMPLETED'){
              this.presentToast("Pagamento effettuato con successo!", "success");
            }else{
              this.presentToast("Pagamento non effettuato!", "danger");
            }
            // this.presentToast("Dati inviati con successo!", "success");
            // this.$store.commit('SET_USER_AUTH', {username: this.user.cfisc, password: this.user.passwd});
            // this.$router.push({name: 'confirm.otp'});
          })
          .catch(() => {
            this.presentToast("Pagamento non effettuato!", "danger");
          });
      },
      submitPaypalPayment(){
        const checkPaymentFeedback = this.checkPaymentFeedback;
        this.paymentFeedback.status = 'PENDING';
        return axios.post('payment-request', {
          tax_code: this.user.cfisc,
          aica_code: this.aicaCode,
          year: this.user.annorif,
          amount: this.user.quote,
        })
          .then(response => {
            console.log(response.data);
            const ref = window.open(response.data.approve_link, '_blank', 'cache=no,location=no');
            // // use this to return to some state after the external browser is closed
            const checkInterval = setInterval(function() {
              if(ref.closed) {
                clearInterval(checkInterval);
                checkPaymentFeedback(response.data.id);
              }
            }, 1000);
          });
      },
      saveToBackend(){
        return axios.post('register', this.user)
          .then(response => {
            console.log(response);
            if(response.data.id){
              this.associateId = response.data.id;
            }
          }).catch(error => {
          if(error.response?.data?.message){
            this.presentToast(error.response?.data?.message, 'danger');
          }else{
            this.presentToast("Si è verificato un errore, riprovare più tardi", 'danger');
          }
          console.log(error);
          console.log(error.response?.data?.message);
        });
      },
      saveData(){
        this.user.quote = this.calculatedQuote;
        this.user.annorif = this.getYearRef;
        if(!this.user.quote){
          this.presentToast('Impossibile recuperare la quota, riprovare più tardi');
          return;
        }
        if(!this.user.annorif){
          this.presentToast('Impossibile recuperare l\'anno di riferimento, riprovare più tardi');
          return;
        }
        this.paymentFeedback.status = 'PENDING';
        this.saveToBackend()
          .then(() => {
            this.saveToAica()
              .then(() => {
                if(this.aicaCode){
                  if(this.user.payment_mode === 'paypal'){
                    this.createPaypalButton();
                  }else{
                    this.paymentFeedback.status = 'COMPLETED';
                  }
                }
            });
          })
      },
      saveSection(){
        this.$refs.sectionForm.validate().then(validation => {
          if(validation.valid === true){
            //salviamo i dati
            // console.log(this.user);
            // axios.post('aica-proxy', this.user, {
            //   auth: {
            //     username: this.$store.state.username,
            //     password: this.$store.state.password,
            //   },
            // }).then(response => {
            //   console.log(response);
            // });
          }else{
            this.presentToast("Non hai inserito tutti i dati obbligatori!", "danger");
          }
        });
      },
      savePayment(){
        this.$refs.paymentForm.validate().then(validation => {
          if(validation.valid === true){
            //salviamo i dati
            // console.log(this.user);
            axios.post('aica-proxy', this.user, {
              auth: {
                username: this.$store.state.username,
                password: this.$store.state.password,
              },
            }).then(response => {
              console.log(response);
            });
          }else{
            this.presentToast("Non hai inserito tutti i dati obbligatori!", "danger");
          }
        });
      },
      async presentToast(message, color) {
        const toast = await toastController.create({
          message: message,
          duration: 1500,
          position: 'bottom',
          color: color || 'primary'
        });

        await toast.present();
      },
    },
  };
