
import {IonRow, IonGrid, IonCol, IonItem, IonSpinner, IonList, IonButton, IonContent, IonPage, IonInput, IonCard, IonCardContent,IonCardHeader, toastController  } from '@ionic/vue';
import axios from 'axios';
import { Http } from '@capacitor-community/http';
export default {
  components: {IonRow, IonGrid, IonCol, IonItem, IonSpinner, IonList, IonButton, IonContent, IonPage, IonInput, IonCard, IonCardContent,IonCardHeader},
  data(){
    return {
      ccode: null,
      loggingIn: false,
    };
  },
  ionViewWillEnter(){
    this.ccode = '';
  },
  methods: {
    submitConfirmationCode(){
      this.loggingIn = true;
      return axios.post('aica-proxy', {
        // Style: 'raw',
        CCODE: this.ccode,
        // SCODE: this.scode,
        // Conferma: 'Conferma'
      }, {
        auth: {
          username: this.$store.state.username,
          password: this.$store.state.password,
        }
      })
        .then(response => {
          console.log(response);
          console.log(response.data);
          if(response.data.codaica){
            this.presentToast("Accesso confermato", 'success');
            this.$store.commit('SET_USER_DATA', response.data);
            this.$store.commit('SET_USER_OTP', this.ccode);
            if(this.$store.state.next_route){
              this.$router.push({name: this.$store.state.next_route});
              this.$store.commit('SET_NEXT_ROUTE', null);
            }else{
              this.$router.push({name: 'dashboard'});
            }
          }else{
            this.presentToast("Il codice inserito non è corretto", 'danger');
          }
          
        })
        .finally(() => {
          this.loggingIn = false;
        });
    },
    async presentToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 1500,
        position: 'bottom',
        color: color || 'primary'
      });

      await toast.present();
    },
  },
};
