
  import {IonTitle, IonToolbar, IonHeader, IonText, IonBadge, IonCard, IonItem ,IonList, IonBackButton,IonButtons, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage, toastController  } from '@ionic/vue';
  import axios from 'axios';
  import { pencil, chevronForwardOutline } from 'ionicons/icons';
  import {mapGetters} from "vuex";
  import _ from 'lodash';

  export default {
    components: {IonTitle, IonToolbar, IonHeader, IonText, IonBadge, IonCard, IonItem ,IonList, IonBackButton,IonButtons,  IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage},
    computed: {
      ...mapGetters({
        skillscards: "getSkillsCards"
      }),
      currentCard(){
        if(this.skillscards){
          return this.skillscards[this.$route.params.index];
        }
        return {};
      },
    },
    setup(){
      return {pencil, chevronForwardOutline};
    },
    data(){
      return {
      
      };
    },
    methods: {
      async presentToast(message, color) {
        const toast = await toastController.create({
          message: message,
          duration: 1500,
          position: 'bottom',
          color: color || 'primary'
        });

        await toast.present();
      },
    },
  };
