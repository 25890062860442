
  import {IonIcon, IonImg, IonTitle, IonToolbar, IonHeader, IonBadge, IonBackButton,IonButtons, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage, toastController  } from '@ionic/vue';
  import axios from 'axios';
  import { pencil, chevronForwardOutline, exitOutline } from 'ionicons/icons';
  import {mapGetters} from "vuex";
  import _ from 'lodash';

  export default {
    components: {IonIcon, IonImg, IonTitle, IonToolbar, IonHeader,IonBadge, IonBackButton,IonButtons,  IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage},
    computed: {
      ...mapGetters({
        articles: "getNews"
      }),
      article(){
        if(this.articles){
          return this.articles[this.$route.params.index];
        }
        return {};
      },
    },
    setup(){
      return {pencil, chevronForwardOutline, exitOutline};
    },
    mounted(){
      if(!this.articles || !this.articles.length){
        this.$store.dispatch('apiGetNews');
      }
    },
    data(){
      return {
      
      };
    },
    methods: {
      async presentToast(message, color) {
        const toast = await toastController.create({
          message: message,
          duration: 1500,
          position: 'bottom',
          color: color || 'primary'
        });

        await toast.present();
      },
    },
  };
