
  import { IonModal, IonDatetime, IonDatetimeButton, IonButtons, IonTitle, IonToolbar, IonHeader, IonLabel, IonItemDivider, IonItemGroup, IonList, IonSpinner, IonButton, IonBackButton, IonContent, IonPage, IonItem, IonInput,
    //IonThumbnail,
    toastController  } from '@ionic/vue';
  import axios from 'axios';
  import { pencil, chevronForwardOutline } from 'ionicons/icons';
  import {mapGetters, mapState} from "vuex";
  import {Form, Field} from 'vee-validate';
  import _ from 'lodash';
  import moment from 'moment';

  export default {
    components: {IonModal, IonDatetime, IonDatetimeButton, IonButtons, IonTitle, IonToolbar, IonHeader, IonLabel, IonItemDivider, IonItemGroup, IonList, IonSpinner, IonButton, IonBackButton, IonContent, IonPage, IonItem, IonInput,
      //IonThumbnail,
      VForm: Form, Field},
    computed: {
      ...mapState({
        user: "user"
      })
    },
    setup(){
      return {pencil, chevronForwardOutline};
    },
    data(){
      return {
        saving: false,
        // user: this.$store.state.user,
      };
    },
    methods: {
      save(){
        this.$refs.formData.validate().then(validation => {
          if(validation.valid === true){
            if(moment(this.user.datanasc).isAfter(moment().subtract(16, 'year'))){
              this.presentToast("L'età risulta essere minore di 16 anni! Verifica la data di nascita");
              return;
            }
            this.saving = true;
            //salviamo i dati
            const data = _.cloneDeep(this.user);
            //data.SCODE = this.$store.state.scode;
            data.CCODE = this.$store.state.ccode;
            const birthDate = data.datanasc.split('-');
            console.log(birthDate);
            data.datanasc = birthDate[2]+'/'+birthDate[1]+'/'+birthDate[0];
            data.Update = true;
            axios.post('aica-proxy', data, {
              auth: {
                username: this.$store.state.username,
                password: this.$store.state.password,
              },
            }).then(response => {
              this.$store.commit('SET_USER_DATA', response.data);
              this.$router.push('dashboard');
            }).finally(() => {
              this.saving = false;
            });
          }else{
            console.log(validation.errors);
            this.presentToast("Non hai inserito tutti i dati obbligatori!", "danger");
          }
        });
        console.log("salvato");
      },
      async presentToast(message, color) {
        const toast = await toastController.create({
          message: message,
          duration: 1500,
          position: 'bottom',
          color: color || 'primary'
        });

        await toast.present();
      },
    },
  };
