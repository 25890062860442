
import { IonItem, IonList, IonSpinner, IonButton, IonContent, IonPage, IonInput, IonCard, IonCardContent,IonCardHeader, toastController  } from '@ionic/vue';
import axios from 'axios';
import { Http } from '@capacitor-community/http';
export default {
  components: {IonItem, IonList, IonSpinner,IonButton, IonContent, IonPage, IonInput, IonCard, IonCardContent,IonCardHeader},
  data(){
    return {
      username: '',
      password: '',
      //scode: 'e5256d2e9ed759281ad85b1af4b8d707971c19c3',
      scode: null,
      ccode: null,
      loggingIn: false,
      step: 0,
    };
  },
  ionViewWillEnter(){
    this.username = '';
    this.password = '';
    this.ccode = '';
  },
  methods: {
    goToRegistration(){
      this.$router.push({name: 'register'});
    },
    goToResetPassword(){
      this.$router.push({name: 'password.reset'});
    },
    login(){
      // this.presentToast("bottom");
      this.loggingIn = true;
      axios.post('aica-proxy', {}, {
        auth: {
          username: this.username,
          password: this.password,
        }
      }).then(response => {
        console.log("response: ", response);
        this.scode = response.data.SCODE;
        this.$store.commit('SET_USER_AUTH', { username: this.username, password: this.password});
        this.$store.commit('SET_NEXT_ROUTE', 'dashboard');
        this.$router.push({name : 'confirm.otp'});
        // this.scode = respData.SCODE;
        //this.step = 1;
      }).catch(error => {
        // console.log(error);
        console.log(error);
        if(error?.response?.status === 401){
          this.presentToast('Le credenziali inserite non sono correte, riprovare.')
        }else{
          this.presentToast('Si è verificato un errore, riprovare.')
        }
      })
        .finally(() => {
        this.loggingIn = false;
      });
    },
    loginTest(){
      // this.presentToast("bottom");
      this.loggingIn = true;
      console.log({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(this.username+':'+this.password),
      });
      fetch('https://soci-test.aicanet.net', {
        mode: 'no-cors',
        method: 'POST',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(this.username+':'+this.password),
        }),
        //body: JSON.stringify({page: this.page})
      })
          .then((response) => response.json())
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.error("errore chiamata http");
            console.error(error);
            console.error(error.response);
          })
          .finally(() => {
            this.loggingIn = false;
          });
      /*
      axios.post('/', {}, {
        auth: {
          username: this.username,
          password: this.password,
        }
      }).then(response => {
        this.scode = response.data.SCODE;
        this.$store.commit('SET_USER_AUTH', { username: this.username, password: this.password});
        this.$store.commit('SET_NEXT_ROUTE', 'dashboard');
        this.$router.push({name : 'confirm.otp'});
        // this.scode = respData.SCODE;
        //this.step = 1;
      }).catch(error => {
        console.log(error);
        console.log(error.response);
        if(error?.response?.status === 401){
          this.presentToast('Le credenziali inserite non sono correte, riprovare.')
        }else{
          this.presentToast('Si è verificato un errore, riprovare.')
        }
      })
        .finally(() => {
        this.loggingIn = false;
      });

       */
    },
    submitConfirmationCode(){
      this.loggingIn = true;
      return axios.post('aica-proxy', {
        // Style: 'raw',
        CCODE: this.ccode,
        // SCODE: this.scode,
        // Conferma: 'Conferma'
      }, {
        auth: {
          username: this.username,
          password: this.password,
        }
      })
        .then(response => {
          console.log(response);
          console.log(response.data);
          // localStorage.setItem('scode', this.scode);
          // localStorage.setItem('ccode', this.ccode);
          this.presentToast("Accesso confermato", 'success');
          this.$store.commit('SET_USER_AUTH', {username: this.username, password: this.password, scode: this.scode, ccode: this.ccode});
          this.$store.commit('SET_USER_DATA', response.data);
          this.$router.push('/dashboard');
        })
        .finally(() => {
          this.loggingIn = false;
        });
    },
    async presentToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 1500,
        position: 'bottom',
        color: color || 'primary'
      });

      await toast.present();
    },
  },
};
