
  import {IonRow, IonGrid, IonCol, IonImg, IonRefresher, IonRefresherContent, IonBadge, IonTitle, IonToolbar, IonHeader, IonList, IonButtons, IonMenuButton, IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage, IonItem, toastController  } from '@ionic/vue';
  //import {IonTitle, IonToolbar, IonHeader, IonList, IonButtons, IonMenuButton, IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage, IonItem,  IonSearchbar, toastController  } from '@ionic/vue';
  import LoadingSpinner from '../components/LoadingSpinner.vue';
  import axios from 'axios';
  import { pencil, chevronForwardOutline } from 'ionicons/icons';
  import {mapGetters} from "vuex";
  import _ from 'lodash';

  export default {
    components: {IonRow, IonGrid, IonCol, LoadingSpinner, IonImg, IonRefresher, IonRefresherContent, IonBadge, IonTitle, IonToolbar, IonHeader, IonList, IonButtons, IonMenuButton,IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage, IonItem},
    //components: {IonTitle, IonToolbar, IonHeader, IonList, IonButtons, IonMenuButton,IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage, IonItem, IonSearchbar},
    computed: {
      ...mapGetters({
        articles: "getNews"
      })
    },
    setup(){
      return {pencil, chevronForwardOutline};
    },
    data(){
      return {
        loading: false,
      };
    },
    beforeMount(){
      this.loading = true;
      this.$store.dispatch('apiGetNews').finally(() => {
        this.loading = false;
      });
    },
    methods: {
      refreshData(event){
        this.$store.dispatch('apiGetNews').finally(() => {
          event.target.complete();
        });
      },
      testSearch(event){
        console.log("event: ", event);
      },
      openDetails(index){
        this.$router.push('/news/'+index);
      },
      async presentToast(message, color) {
        const toast = await toastController.create({
          message: message,
          duration: 1500,
          position: 'bottom',
          color: color || 'primary'
        });

        await toast.present();
      },
    },
  };
