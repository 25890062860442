
  import {IonTitle, IonToolbar, IonHeader, IonLabel, IonText, IonList, IonButtons, IonBackButton, IonContent, IonPage, IonItem, toastController  } from '@ionic/vue';
  import axios from 'axios';
  import { pencil, chevronForwardOutline } from 'ionicons/icons';
  import {mapGetters, mapState} from "vuex";
  import _ from 'lodash';

  export default {
    components: {IonTitle, IonToolbar, IonHeader, IonLabel, IonText, IonList, IonButtons, IonBackButton,IonContent, IonPage, IonItem},
    computed: {
      ...mapState({
        user: "user"
      })
    },
    setup(){
      return {pencil, chevronForwardOutline};
    },
    data(){
      return {
      
      };
    },
    methods: {
      save(){
        this.$refs.formData.validate().then(validation => {
          if(validation.valid === true){
            //salviamo i dati
            // console.log(this.user);
            axios.post('aica-proxy', this.user, {
              auth: {
                username: this.$store.state.username,
                password: this.$store.state.password,
              },
            }).then(response => {
              console.log(response);
            });
          }else{
            this.presentToast("Non hai inserito tutti i dati obbligatori!", "danger");
          }
        });
        console.log("salvato");
      },
      async presentToast(message, color) {
        const toast = await toastController.create({
          message: message,
          duration: 1500,
          position: 'bottom',
          color: color || 'primary'
        });

        await toast.present();
      },
    },
  };
