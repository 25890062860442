
import { alertController, IonButtons, IonMenuButton,IonTitle, IonToolbar, IonHeader, IonContent, IonPage, IonItem, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonIcon, IonCardContent, toastController, IonProgressBar, IonRippleEffect  } from '@ionic/vue';
import axios from 'axios';
import { notifications, pencil, chevronForwardOutline } from 'ionicons/icons';
import {mapGetters, mapState} from "vuex";
import { useRouter } from 'vue-router';
import JsBarcode from 'jsbarcode';
import moment from 'moment';
export default {
  components: {IonButtons, IonMenuButton, IonTitle, IonToolbar, IonHeader, IonContent, IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonItem, IonText, IonIcon, IonCardContent, IonProgressBar, IonRippleEffect},
  computed: {
    ...mapState({
      user: "user",
      parameters : "parameters",
    }),
    isRegistrationOpen(){
      if(!this.parameters || !this.parameters.REGISTER_ACTIVE_FROM || !this.parameters.REGISTER_ACTIVE_TO || !this.parameters.REGISTER_ACTIVE_YEAR){
        //se non ci sono i parametri, non facciamo registrare
        return false;
      }
      if(!(moment(this.parameters.REGISTER_ACTIVE_FROM).isBefore(moment()) && moment(this.parameters.REGISTER_ACTIVE_TO).isAfter(moment()))){
        //se il periodo non è compreso tra quello dei parametri, non facciamo registrare
        return false;
      }
      if(this.hasUserRenewed){
        return false;
      }
      return true;
    },
    hasUserRenewed(){
      if(this.user?.Quota[0]?.annorif >= this.parameters.REGISTER_ACTIVE_YEAR ){
        return true;
      }
      return false;
    },
    getNextRenew(){
      if(this.user && this.user.Quota && this.user.Quota.length>0){
        const date = this.user.Quota[0].data.split('/');
        let nextDate = null;
        if(this.parameters && this.parameters.REGISTER_ACTIVE_FROM){
          nextDate = moment(this.parameters.REGISTER_ACTIVE_FROM);
        }
        let deadline = null;
        if(this.parameters && this.parameters.REGISTER_ACTIVE_TO){
          deadline = moment(this.parameters.REGISTER_ACTIVE_TO);
        }
        return {
          nextDate: nextDate ? nextDate.format("DD/MM/YYYY") : '',
          deadline: deadline ? deadline.format("DD/MM/YYYY") : '',
          progress: 1-(nextDate.diff(moment(), 'd')/365),
        };
      }
      return {
        // nextDate: 'N.D.'
      };
    },
  },
  watch: {
    'user.codaica': function(newValue){
      if(newValue){
        this.initBarcode();
      }
    }
  },
  setup(){
    const router = useRouter();
    return {pencil, chevronForwardOutline, router};
  },
  data(){
    return {
      barcodeImage: null,
      test: "sadasdsa",
      // user: this.$store.state.user,
    };
  },
  beforeMount(){
    if(!this.user){
      console.log('there is not user');
      this.$router.push({name: 'login'});
    }
  },
  mounted(){
    this.initBarcode();
  },
  methods: {
    refreshData(event){
      this.$store.dispatch('initAppData').finally(() => {
        event.target.complete();
      });
    },
    goToRenew(){
      this.$router.push({name: 'renew'});
    },
    async deleteProfile(){
      const alert = await alertController.create({
        header: 'Eliminazione profilo',
        // subHeader: '',
        message: 'Confermando, accetti di inoltrare alla segreteria la richiesta di eliminazione del tuo account.',
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            handler: () => {
              console.log('Alert canceled');
            },
          },
          {
            text: 'Confermo',
            role: 'confirm',
            handler: () => {
              this.presentToast("Richiesta inoltrata", "success");
            },
          },
        ],
      });

      await alert.present();
    },
    initBarcode(){
      JsBarcode(".barcode").init();
      // QRCode.toDataURL(this.user.codaica, {version: 1}, (err, url)=>{
      //   console.log(url);
      //   this.barcodeImage = url;
      // });
    },
    goToProfile(){
      //this.$router.push('/profile');
      this.router.push({name: 'profile'});
    },
    async presentToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 1500,
        position: 'bottom',
        color: color || 'primary'
      });

      await toast.present();
    },
  },
};
