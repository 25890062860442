import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import ResetPasswordPage from '../views/ResetPasswordPage.vue';
import DashboardPage from '../views/DashboardPage.vue';
import DefaultLayout from '../views/DefaultLayout.vue';
import ProfilePage from "@/views/ProfilePage.vue";
// import RegisterFullPage from "@/views/RegisterFullPage.vue";
import RegisterPage from "@/views/RegisterPage.vue";
import InsertSezPage from "@/views/InsertSezPage.vue";
import ConfirmOtpPage from "@/views/ConfirmOtpPage.vue";
import SkillscardsPage from "@/views/SkillscardsPage.vue";
import SkillscardDetailsPage from "@/views/SkillscardDetailsPage.vue";
import CertificationsListPage from "@/views/CertificationsListPage.vue";
import CertificationsDetailsPage from "@/views/CertificationsDetailsPage.vue";
import AffiliationsListPage from "@/views/AffiliationsListPage.vue";
import AffiliationsDetailsPage from "@/views/AffiliationsDetailsPage.vue";
import RenewEnrollmentPage from "@/views/RenewEnrollmentPage.vue";
import NewsListPage from "@/views/NewsListPage.vue";
import NewsDetailsPage from "@/views/NewsDetailsPage.vue";
import PaymentsPage from "@/views/PaymentsPage.vue";
import SearchUser from "@/views/SearchUser.vue";
import {store} from '../store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/password-reset',
    name: 'password.reset',
    component: ResetPasswordPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/confirm-otp',
    name: 'confirm.otp',
    component: ConfirmOtpPage,
    meta: {
      requiresAuth: false,
    },
  },
  // {
  //   path: '/registerfull',
  //   name: 'registerfull',
  //   component: RegisterFullPage,
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },

  {
    path: '/',
    component: DefaultLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardPage
      },
      {
        path: 'profile',
        name: 'profile',
        component: ProfilePage
      },
      {
        path: 'payments',
        name: 'payments',
        component: PaymentsPage
      },
      {
        path: 'sez',
        name: 'sez',
        component: InsertSezPage
      },
      {
        path: '/renew',
        name: 'renew',
        component: RenewEnrollmentPage
      },
      {
        path: 'news',
        name: 'news.index',
        component: NewsListPage
      },
      {
        path: 'news/:index',
        name: 'news.details',
        component: NewsDetailsPage
      },
      {
        path: 'search',
        name: 'search',
        component: SearchUser
      },
      {
        path: 'skillscards',
        name: 'skillscards',
        component: SkillscardsPage
      },
      {
        path: 'skillscards/:index',
        name: 'skillscards.details',
        component: SkillscardDetailsPage
      },
      {
        path: 'certifications',
        name: 'certifications',
        component: CertificationsListPage
      },
      {
        path: 'certifications/:index',
        name: 'certifications.details',
        component: CertificationsDetailsPage
      },
      {
        path: 'affiliations',
        name: 'affiliations',
        component: AffiliationsListPage
      },
      {
        path: 'affiliations/:index',
        name: 'affiliations.details',
        component: AffiliationsDetailsPage
      },
      {
        path: '/folder/:id',
        component: () => import ('../views/FolderPage.vue')
      }
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from) => {
  store.commit('SET_CURRENT_PATH', to.fullPath);
  console.log('going to route: ', to.fullPath);
  console.log("IsUserLoggedIn:", store.getters.isUserLoggedIn);
  //se navighiamo ad una pagina protetta ma non siamo autenticati allora ritorniamo al login
  if(to.meta.requiresAuth && !store.getters.isUserLoggedIn){
    console.log("requiresAuth and ! logged in");
    router.push('/login');
  }
  if(store.getters.isUserLoggedIn && to.name === 'confirm.otp'){
    return false;
  }
  // ...
  // explicitly return false to cancel the navigation
  // return false
});

export default router
