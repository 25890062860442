import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { color: "primary" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Skillcard")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              ($data.loading)
                ? (_openBlock(), _createBlock(_component_loading_spinner, {
                    key: 0,
                    name: "dots"
                  }))
                : _createCommentVNode("", true),
              (!$data.loading && (!_ctx.skillscards || !_ctx.skillscards.length))
                ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Nessuna skillcard trovata ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.skillscards, (skillCard, skillcardIndex) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      lines: "none",
                      key: 'skillcard_'+skillcardIndex
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card, {
                          style: {"width":"100%"},
                          onClick: ($event: any) => ($options.goToDetails(skillcardIndex))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_header, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Skills Card " + _toDisplayString(skillCard.genre ?? '') + " ", 1),
                                    _createElementVNode("h2", null, "N° " + _toDisplayString(skillCard.skills_card), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_card_subtitle, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("rilasciata il " + _toDisplayString(skillCard.release_date), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_card_content, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(skillCard.exams.length) + " certificazioni trovate ", 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_button, {
                              fill: "clear",
                              onClick: ($event: any) => ($options.goToDetails(skillcardIndex))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Vedi")
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}