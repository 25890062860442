
import {
  IonIcon,
  IonButton,
  IonContent,
  IonPage,
  IonItem,
  toastController,
  alertController,
  IonButtons, IonToolbar, IonBackButton, IonHeader, IonTitle
} from '@ionic/vue';
  import axios from 'axios';
  import { pencil, chevronForwardOutline, checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
  import { Browser } from '@capacitor/browser';

import {mapGetters, mapState} from "vuex";
  import _ from 'lodash';

  export default {
    components: {
      IonIcon,
      IonTitle,
      IonHeader,
      IonBackButton,
      IonToolbar,
      IonButtons,
      IonButton, IonContent, IonPage, IonItem},
    computed: {
      ...mapState({
        parameters: "parameters",
        user: "user"
      }),
      getYearRef(){
        if(this.parameters && this.parameters.REGISTER_ACTIVE_YEAR){
          return this.parameters.REGISTER_ACTIVE_YEAR;
        }
        return '';
      },
      getQuota(){
        if(this.user && this.user.Quota && this.user.Quota.length>0 && this.user.Quota[0].quota){
          // console.log(this.user.Quota[0]);
          return this.user.Quota[0].quota;
        }else{
          let amount = 80;
          if(this.user.tiposocio.toLowerCase() === 'ordinario' && (this.user.docente_ata || this.user.supervisoreecdl || this.user.digital_animator)){
            amount = 20;
          }else if(this.user.clubFIDA || this.user.IEEE || this.user.aium || this.user.ACM){
            amount = 64;
          }
          return amount;
        }
      },
    },
    mounted(){
      const paypalScript = document.createElement('script');
      if(process.env.NODE_ENV === 'development'){
        console.log("development");
        paypalScript.setAttribute('src',"https://www.paypal.com/sdk/js?client-id=AXDAFDEJUY8PLj_T4zK48D7_0tAZF4CrQdbEdIUX98aN-zK89IO03oHEZx-YfA5T1DtpIALd4JNx0PxF&components=buttons&currency=EUR");
      }else{
        console.log("production");
        paypalScript.setAttribute('src',"https://www.paypal.com/sdk/js?client-id=Ab15W2hMyi03hkTN0f43xD1E_BEX7QqPXeWHD0LS29o88ZBPcWzpiOZwlD8Sp0hQ5iF87tMJv4szS6DU&components=buttons&currency=EUR");
      }
      
      document.head.appendChild(paypalScript);
    },
    setup(){
      return {pencil, chevronForwardOutline, checkmarkCircleOutline, closeCircleOutline};
    },
    data(){
      return {
        paymentFeedback: {
          status: null,
        },
        paymentMode: null,
        paypalButtonsCreated: false,

      // user: {
        //   Insert: "True",
        //   cfisc: this.$store.state.username,
        //   CCODE: this.$store.state.ccode,
        //   "What": "1",
        //   sigla: '',
        // },
      };
    },
    ionViewDidEnter(){
      // console.log("ionViewDidEnter");
      // this.createPaypalButton();
    },
    methods: {
      async showConfirmationAlert(){
        const goToDashboard = this.goToDashboard;
        const alert =  await alertController.create({
          header: 'Pagamento completato',
          message: 'La tua associazione per l\'anno '+this.getYearRef+" è stata pagata con successo",
          buttons: [
            {
              text: 'Ok',
              role: 'confirm',
              handler(){
                goToDashboard();
              },
            }],
        });
        await alert.present();
      },
      createPaypalButton(){
        const taxCode = this.user.cfisc;
        const aicaCode = this.user.codaica;
        const tipoSocio = this.user.tiposocio;
        const year = this.getYearRef;
        const amount = this.getQuota;
        const paymentFeedback = this.paymentFeedback;
        const showConfirmationAlert = this.showConfirmationAlert;
        // const vm = this;
        this.paypalButtonsCreated = true;
        window.paypal.Buttons({
          style: {
            label: 'pay',
            layout: 'horizontal',
            tagline: 'false'
          },
          createOrder() {
            // axios.post('payment-request', {
            //   tax_code: this.user.cfisc,
            //   aica_code: this.aicaCode,
            //   year: this.getYearRef,
            //   amount: this.getQuota,
            // })
            return fetch(axios.defaults.baseURL+"payment-request", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                tax_code: taxCode,
                tipo_socio: tipoSocio,
                aica_code: aicaCode,
                year: year,
                amount: amount,
              })
            })
              .then((response) => response.json())
              .then((order) => order.id);
          },
          onApprove(data) {
            console.log("pagamento effettuato con successo");
            // This function captures the funds from the transaction.
            return fetch(axios.defaults.baseURL+"payment-submit", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                orderID: data.orderID
              })
            })
              .then((response) => response.json())
              .then((details) => {
                // This function shows a transaction success message to your buyer.
                console.log('onApproveResponse: ', JSON.stringify(details));
                paymentFeedback.status = details.status;
                if(details.status === 'COMPLETED'){
                  showConfirmationAlert();
                }
                
                // alert('Transaction completed by ' + details.payer.name.given_name);
              });
          },
          onCancel(data){
            console.log('onCancelData: ', JSON.stringify(data));
          },
        }).render('#paypal-button-container');
      },
      goToDashboard(){
        this.$store.dispatch('apiGetUserData').then(() =>{
          this.$router.push({name: 'dashboard'});
        });
        
      },
      async presentToast(message, color) {
        const toast = await toastController.create({
          message: message,
          duration: 1500,
          position: 'bottom',
          color: color || 'primary'
        });

        await toast.present();
      },
    },
  };
