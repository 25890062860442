import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import {store} from './store';

declare global {
  interface Window { // ⚠️ notice that "Window" is capitalized here
    paypal: any;
  }
}


import axios from 'axios';
// axios.defaults.baseURL = 'https://soci-test.aicanet.net';
axios.defaults.baseURL = 'https://aica-soci.d74.cloud/api/';
// axios.defaults.withCredentials = true;

if(process.env.NODE_ENV === 'development'){
  axios.defaults.baseURL = 'http://aica-web.local/api/';
  // axios.defaults.baseURL = 'https://aica-soci.d74.cloud/api/';
}

axios.interceptors.response.use(function (response) {
  if(response.data && response.data === 'From e-mail'){
    if(router.currentRoute && router.currentRoute['name'] === 'password.reset'){
      return;
    }
    localStorage.removeItem('ccode');
    router.push({name : 'confirm.otp'});
  }
  return response;
}, function (error) {
  if (error.response.status === 401 ) {
    localStorage.removeItem('ccode');
    if(error.response.data && error.response.data.SCODE === 'From e-mail'){
      router.push({name : 'confirm.otp'});
    }else{
      router.push({name: 'login'});
    }

  }
  return Promise.reject(error);
});

import { IonicVue } from '@ionic/vue';

import {configure as VeeConfigure, defineRule} from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import { required, confirmed} from "@vee-validate/rules";
defineRule('required', required);
defineRule('confirmed', confirmed);
import it from '@vee-validate/i18n/dist/locale/it.json';
VeeConfigure({
  validateOnInput: true,
  generateMessage: localize({
    it,
  }),
});
setLocale('it');

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

if(localStorage.getItem('ccode')){
  store.commit('SET_USER_AUTH', {username: localStorage.getItem('username'), password: localStorage.getItem('password')});
  store.commit('SET_USER_OTP', localStorage.getItem('ccode'));
}

store.dispatch('initAppData').finally(() => {
  initApp();
});

function initApp(){
  const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(store);
  router.isReady().then(() => {
    app.mount('#app');
  });
}
