
import {IonRow, IonGrid, IonCol, IonItem, IonList, IonSpinner, IonButton, IonContent, IonPage, IonInput, IonCard, IonCardContent,IonCardHeader, toastController  } from '@ionic/vue';
import axios from 'axios';
import { Http } from '@capacitor-community/http';
export default {
  components: {IonRow, IonGrid, IonCol, IonItem, IonList, IonSpinner,IonButton, IonContent, IonPage, IonInput, IonCard, IonCardContent,IonCardHeader},
  data(){
    return {
      username: '',
      password: '',
      passwordConfirm: '',
      scode: null,
      ccode: null,
      submitting: false,
      step: 0,
    };
  },
  beforeMount(){
    this.$store.commit('CLEAR_USER_AUTH');
    this.username = '';
    this.password = '';
    this.passwordConfirm = '';
    this.ccode = '';
  },
  methods: {
    submit(){
      // this.presentToast("bottom");
      this.submitting = true;
      axios.post('aica-proxy', {
        "GETPWRESET": "GETPWRESET"
      }, {
        auth: {
          username: this.username,
          password: '',
        }
      }).then(response => {
        this.step = 1;
      }).catch(error => {
        console.log(error);
        console.log(error.response);
        this.presentToast('Si è verificato un errore, riprovare.')
      })
        .finally(() => {
        this.submitting = false;
      });
    },
    submitConfirmationCode(){
      if(this.password !== this.passwordConfirm){
        this.presentToast('Le due password non coincidono', "danger");
        return;
      }
      this.submitting = true;
      return axios.post('aica-proxy', {
        // Style: 'raw',
        SETPWRESET: "SETPWRESET",
        CCODE: this.ccode,
        passwd: this.password,
        // SCODE: this.scode,
        // Conferma: 'Conferma'
      }, {
        auth: {
          username: this.username,
          password: this.password,
        }
      })
        .then(response => {
          console.log(response);
          console.log(response.data);
          // localStorage.setItem('scode', this.scode);
          // localStorage.setItem('ccode', this.ccode);
          this.presentToast("Password cambiata", 'success');
          this.$store.commit('SET_USER_AUTH', {username: this.username, password: this.password, scode: this.scode, ccode: this.ccode});
          this.$store.commit('SET_USER_DATA', response.data);
          this.$router.push('/dashboard');
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    async presentToast(message, color) {
      const toast = await toastController.create({
        message: message,
        duration: 1500,
        position: 'bottom',
        color: color || 'primary'
      });

      await toast.present();
    },
  },
};
