
<template>
  <div style="display: flex; min-height:50px;justify-content: center; align-items: center; width:99%;">
    <ion-spinner name="dots"></ion-spinner>
  </div>
</template>

<script>
  import {IonSpinner} from '@ionic/vue';
  
  export default {
    name: "LoadingSpinner",
    components: {IonSpinner},
  }
</script>