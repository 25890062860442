
  import { IonMenuButton, IonButtons, IonTitle, IonToolbar, IonHeader, IonButton, IonList, IonText, IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage, IonItem, toastController  } from '@ionic/vue';
  import axios from 'axios';
  import { pencil, chevronForwardOutline } from 'ionicons/icons';
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import {mapGetters, mapState} from "vuex";
  import _ from 'lodash';

  export default {
    components: {LoadingSpinner, IonMenuButton, IonButtons, IonTitle, IonToolbar, IonHeader, IonButton, IonList, IonText, IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonContent, IonPage, IonItem},
    computed: {
      ...mapGetters({
        skillscards: "getSkillsCards"
      })
    },
    setup(){
      return {pencil, chevronForwardOutline};
    },
    data(){
      return {
        // skillcards: [],
        loading: true,
      };
    },
    methods: {
      getData(){
        axios.post('aica-proxy', {
          CCODE: this.$store.state.ccode,
          ATLAS: "ATLAS",
        }, {
          auth: {
            username: this.$store.state.username,
            password: this.$store.state.password,
          },
        }).then(response => {
          this.skillcards = response.data.skillscard;
        });
      },
      goToDetails(index){
        this.$router.push({name: 'skillscards.details', params: {index}});
      },
      async presentToast(message, color) {
        const toast = await toastController.create({
          message: message,
          duration: 1500,
          position: 'bottom',
          color: color || 'primary'
        });

        await toast.present();
      },
    },
    mounted(){
      if(!this.skillscards || !this.skillscards.length){
        this.loading = true;
        this.$store.dispatch('apiGetSkillsCards').finally(() => {
          this.loading = false;
        });
      }
      // this.getData();
    },
  };
