import {createStore} from 'vuex';
import axios from 'axios';
import router from "./router";
import moment from "moment";

function defaultState() {
    return {
        user: null,
        parameters: {},
        skillscards: [],
        news: [],
        certifications: [],
        affiliations: [],
        //user: null,
        username: "",
        password: "",
        ccode: null,
        scode: null,
        next_route: null,
        currentRoutePath: '',
        debug_mode: process.env.NODE_ENV === 'development',
    }

}

export const store = createStore({
    state: defaultState,
    mutations:{
        RESET_STATE(state){
            const s = defaultState();
            Object.keys(s).forEach(key => {
              if(key !== 'parameters'){
                state[key] = s[key];
              }
            });
            localStorage.removeItem('username');
            localStorage.removeItem('password');
            localStorage.removeItem('ccode');
        },
        SET_USER_DATA(state, data) {
            if(data.datanasc && data.datanasc !== '00/00/0000'){
              const birthDate = data.datanasc.split('/');
              console.log(birthDate);
              data.datanasc = birthDate[2]+'-'+birthDate[1]+'-'+birthDate[0];
            }else{
              data.datanasc = moment().format("YYYY-MM-DD");
            }
            state.user = data;
        },
        SET_PARAMETERS(state, data) {
            state.parameters = data;
        },
        SET_NEXT_ROUTE(state, data) {
            state.next_route = data;
        },
        SET_NEWS(state, data) {
            state.news = data;
        },
        SET_CERTIFICATIONS(state, data) {
            state.certifications = data;
        },
        SET_CURRENT_PATH(state, data) {
            state.currentRoutePath = data;
        },
        SET_AFFILIATIONS(state, data) {
            state.affiliations = data;
        },
        SET_USER_AUTH(state, data) {
            state.username = data.username;
            state.password = data.password;
        },
        CLEAR_USER_AUTH(state){
          state.username = null;
          state.password = null;
          state.ccode = null;
          localStorage.removeItem('username');
          localStorage.removeItem('password');
          localStorage.removeItem('ccode');
        },
        CLEAR_USER_OTP(state){
          state.ccode = null;
          localStorage.removeItem('ccode');
        },
        SET_USER_OTP(state, data) {
            state.ccode = data;
            localStorage.setItem('username', state.username);
            localStorage.setItem('password', state.password);
            localStorage.setItem('ccode', data);
        },
        SET_SKILLS_CARDS(state, data) {
          console.log(data);
          state.skillscards = data;
        },
    },
    actions: {
        initAppData(context){
            return Promise.all([
                context.dispatch('apiGetUserData'),
                context.dispatch('apiGetParameters'),
            ]);
        },
        loginCheck(context) {
            return new Promise((resolve, reject) => {
                axios.get('/api/login-check')
                    .then(res => {
                        resolve();
                    })
                    .catch(err => {
                        reject();
                    });
            })
        },
      apiGetUserData(context) {
        if(!context.state.ccode || !context.state.username || !context.state.password){
          console.log("missing credentials");
          return;
        }
        return new Promise((resolve, reject) => {

          axios.post('aica-proxy', {
            CCODE: context.state.ccode,
          }, {
            auth: {
              username: context.state.username,
              password: context.state.password,
            }
          })
            .then(response => {
              if(response.data.SCODE === 'From e-mail'){
                //ccode non corretto
                context.commit('CLEAR_USER_OTP');
                router.push({name : 'confirm.otp'});
              }else{
                context.commit('SET_USER_DATA', response.data);
              }

              resolve();
            })
            .catch(error => {
              let errorMessage = error.message;
              if (error.response.data.error) {
                errorMessage = error.response.data.error;
              }
              console.error('ERROR GETTING COMPLEXITY LEVELS DATA: ', errorMessage);
              reject();
            });
        })
      },
      apiGetParameters(context) {
        return axios.get('parameters')
          .then(response => {
            context.commit('SET_PARAMETERS', response.data);
          })
          .catch(error => {
            let errorMessage = error.message;
            if (error.response.data.error) {
              errorMessage = error.response.data.error;
            }
            console.error('ERROR GETTING PARAMETERS: ', errorMessage);
          });
      },
      apiGetSkillsCards(context) {
        return new Promise((resolve, reject) => {
          axios.post('aica-proxy', {
            ATLAS: "ATLAS",
            CCODE: context.state.ccode,
          }, {
            auth: {
              username: context.state.username,
              password: context.state.password,
            },
          })
            .then(response => {
              context.commit('SET_SKILLS_CARDS', response.data.skillscard);
              resolve();
            })
            .catch(error => {
              let errorMessage = error.message;
              if (error.response.data.error) {
                errorMessage = error.response.data.error;
              }
              console.error('ERROR GETTING COMPLEXITY LEVELS DATA: ', errorMessage);
              reject();
            });
        })
      },
      apiGetNews(context) {
        return new Promise((resolve, reject) => {
          axios.get('articles/news')
            .then(response => {
              context.commit('SET_NEWS', response.data);
              resolve();
            })
            .catch(error => {
              let errorMessage = error.message;
              if (error.response.data.error) {
                errorMessage = error.response.data.error;
              }
              console.error('ERROR GETTING NEWS DATA: ', errorMessage);
              reject();
            });
        })
      },
      apiGetCertifications(context) {
        return new Promise((resolve, reject) => {
          axios.get('articles/certifications')
            .then(response => {
              context.commit('SET_CERTIFICATIONS', response.data);
              resolve();
            })
            .catch(error => {
              let errorMessage = error.message;
              if (error.response.data.error) {
                errorMessage = error.response.data.error;
              }
              console.error('ERROR GETTING CERTIFICATIONS DATA: ', errorMessage);
              reject();
            });
        })
      },
      apiGetAffiliations(context) {
        return new Promise((resolve, reject) => {
          axios.get('articles/affiliations')
            .then(response => {
              context.commit('SET_AFFILIATIONS', response.data);
              resolve();
            })
            .catch(error => {
              let errorMessage = error.message;
              if (error.response.data.error) {
                errorMessage = error.response.data.error;
              }
              console.error('ERROR GETTING AFFILIATIONS DATA: ', errorMessage);
              reject();
            });
        })
      },
    },
    getters: {
      isUserLoggedIn: state => {
          return !!(state.username && state.password && state.ccode);
      },
      isDebugModeEnabled: state => {
          return state.debug_mode;
      },
      getUserData: state => {
          //user è l'utente che compila il questionario
          return state.user
      },
      getNews: state => {
          return state.news;
      },
      getRouteCurrentPath: state => {
          return state.currentRoutePath;
      },
      getCertifications: state => {
          return state.certifications;
      },
      getAffiliations: state => {
          return state.affiliations;
      },
      getSkillsCards: state => {
          return state.skillscards;
      },
      getParameter: state => key => {
        if(state.parameters[key]){
          return state.parameters[key];
        }
        return null;
      },
      getParameters: state => {
        return state.parameters;
      },
    }
});
