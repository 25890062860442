
    import {
      IonTitle,
      IonToolbar,
      IonHeader,
      IonButton,
      IonSkeletonText,
      IonList,
      IonModal,
      IonInput,
      IonContent,
      IonPage,
      IonItem,
      IonSelect,
      IonSelectOption,
      IonThumbnail,
      IonLabel,
      IonButtons,
      IonIcon,
      IonMenuButton,
      IonSearchbar,
      IonAvatar,
      toastController
    } from '@ionic/vue';
    import axios from 'axios';
    import { pencil, chevronForwardOutline, optionsOutline } from 'ionicons/icons';
    import {mapGetters} from "vuex";
    import {Form, Field} from 'vee-validate';
    import _ from 'lodash';
    import { toValue } from 'vue';

    export default {
        components: { IonTitle, IonToolbar, IonHeader, IonButton, IonSkeletonText, IonList, IonModal, IonInput, IonButtons, IonMenuButton, IonIcon, IonContent, IonPage, IonItem,  IonThumbnail, IonSelect, IonSelectOption, IonSearchbar, IonAvatar, IonLabel },
        computed: {
            ...mapGetters({
                user: "user"
            })
        },
        setup(){
            return {pencil, chevronForwardOutline, optionsOutline};
        },
        data(){
            return {
              searching: false,
                sezioni: [
                    {code: "", name: "Tutto"},
                    {code: "CAL", name: "Calabria"},
                    {code: "CAM", name: "Campania"},
                    {code: "EMR", name: "Emilia Romagna - Marche"},
                    {code: "INT", name: "Internazionale"},
                    {code: "LAZ", name: "Lazio"},
                    {code: "LIG", name: "Liguria"},
                    {code: "LOM", name: "Lombardia"},
                    {code: "FVG", name: "Nord-Est"},
                    {code: "PIE", name: "Piemonte"},
                    {code: "PUG", name: "Puglia"},
                    {code: "SIC", name: "Sicilia"},
                    {code: "TOS", name: "Toscana"},
                    {code: "VDA", name: "Valdadige"},
                ],
              users: null,
                // users: [
                //     {
                //         "ROWNUM": "",
                //         "SCODE": "",
                //         "What": "",
                //         "codaica": "",
                //         "dataiscr": "",
                //         "cfisc": "",
                //         "cognome": "",
                //         "nome": "",
                //         "tiposocio": "",
                //         "Quota": [],
                //         "datanasc": "",
                //         "pvnasc": "",
                //         "comnasc": "",
                //         "naznasc": "",
                //         "recindir": "",
                //         "reccap": "",
                //         "recpv": "",
                //         "reccom": "",
                //         "reccext": "",
                //         "recnaz": "",
                //         "recph": "",
                //         "reccell": "",
                //         "recemail": "",
                //         "azragsoc": "",
                //         "azcfisc": "",
                //         "azpiva": "",
                //         "azindir": "",
                //         "azcap": "",
                //         "azpv": "",
                //         "azcom": "",
                //         "azcext": "",
                //         "aznaz": "",
                //         "azph": "",
                //         "azcell": "",
                //         "azemail": "",
                //         "Sezione": [
                //             {
                //                 "sigla": ""
                //             }
                //         ]
                //     },
                // ],
                search: {
                  "COGNOMER": "",
                  "NOMER": "",
                  "CFISCR": "",
                  "SIGLASEZR": ""
                },
            };
        },
        methods: {
          resetParameters(){
            Object.keys(this.search).forEach(key => {
              this.search[key] = '';
            })
          },
          closeModal(){
            this.$refs.modal.$el.dismiss();
          },
          onWillDismiss(){
            console.log('asdas');
          },
            getUsers(response){
                let i, j;
                for (i=0; i<response.data["Lista soci"].length; i++) {
                    for (j=0; j<this.users.length; j++) {
                        if (this.users[j] === response.data["Lista soci"][i]){
                            return;
                        }else{
                            this.users.push(response.data["Lista soci"][i]);
                        }
                    }
                }
            },

            searchUser(event){
              this.$refs.modal.$el.dismiss();
              //Ricerca per cognome, nome o codaica
                const cognomer = (this.search.COGNOMER ?? '') + "%";
                const nomer = (this.search.NOMER ?? '') + "%";
                const cfiscr = this.search.CFISCR;
                const siglasezr = this.search.SIGLASEZR;


                /*
                for (const ind in this.users){
                    if (this.users[ind]["cognome"] == cognomer || this.users[ind]["nome"] == nomer || this.users[ind]["cfisc"] == cfiscr){
                        console.log("user: ", this.users[ind]["cognome"], this.users[ind]["nome"], this.users[ind]["cfisc"] );
                        return this.users;
                    }
                }*/

                this.searching = true;
                axios.post('aica-proxy', {
                        COGNOMER: cognomer,
                        NOMER: nomer,
                        CCODE: this.$store.state.ccode,
                        What: "0",
                        Ricerca: true,
                        CFISCR: cfiscr,
                        SIGLASEZR: siglasezr,
                    },{
                        auth: {
                            username: this.$store.state.username,
                            password: this.$store.state.password,
                        }
                    }

                ).then(response => {
                  if(response.data["Lista soci"]){
                    this.users = response.data["Lista soci"];
                  }
                }).catch(error => {
                    this.presentToast('Si è verificato un errore, riprovare.')

                }).finally(() => {
                  this.searching = false;
                });

            },
            async presentToast(message, color) {
                const toast = await toastController.create({
                    message: message,
                    duration: 1500,
                    position: 'bottom',
                    color: color || 'primary'
                });

                await toast.present();
            },
        },
    };
