
  import { IonButton, IonContent, IonPage, IonItem, IonSelect, IonSelectOption, IonText, IonThumbnail, IonLabel, toastController  } from '@ionic/vue';
  import axios from 'axios';
  import { pencil, chevronForwardOutline } from 'ionicons/icons';
  import {mapGetters, mapState} from "vuex";
  import {Form, Field} from 'vee-validate';
  import _ from 'lodash';

  export default {
    components: {IonButton, IonContent, IonPage, IonItem, IonSelect, IonSelectOption, IonText, IonThumbnail,IonLabel, VForm: Form, Field},
    computed: {
      ...mapState({
        //user: "user"
      })
    },
    setup(){
      return {pencil, chevronForwardOutline};
    },
    data(){
      return {
        sezioni: [
          {code: "CAL", name: "Calabria"},
          {code: "CAM", name: "Campania"},
          {code: "EMR", name: "Emilia Romagna - Marche"},
          {code: "INT", name: "Internazionale"},
          {code: "LAZ", name: "Lazio"},
          {code: "LIG", name: "Liguria"},
          {code: "LOM", name: "Lombardia"},
          {code: "FVG", name: "Nord-Est"},
          {code: "PIE", name: "Piemonte"},
          {code: "PUG", name: "Puglia"},
          {code: "SIC", name: "Sicilia"},
          {code: "TOS", name: "Toscana"},
          {code: "VDA", name: "Valdadige"},
        ],
        user: {
          Insert: "True",
          cfisc: "RSSMRA90L24L781A",
          CCODE: "900124032",
          "What": "1",
          sigla: '',
        },
      };
    },
    methods: {
      save(){
        this.$refs.formData.validate().then(validation => {
          if(validation.valid === true){
            //salviamo i dati
            // console.log(this.user);
            axios.post('aica-proxy', this.user, {
              auth: {
                username: this.$store.state.username,
                password: this.$store.state.password,
              },
            }).then(response => {
              console.log(response);
            });
          }else{
            this.presentToast("Non hai inserito tutti i dati obbligatori!", "danger");
          }
        });
        console.log("salvato");
      },
      async presentToast(message, color) {
        const toast = await toastController.create({
          message: message,
          duration: 1500,
          position: 'bottom',
          color: color || 'primary'
        });

        await toast.present();
      },
    },
  };
